import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { colorScheme } from './colour-scheme.jss';

const breakpoints = createBreakpoints({});
const palette = colorScheme[process.env.REACT_APP_BRAND];
const primaryFont = process.env.REACT_APP_BRAND === 'dacia' ? 'Block' : 'NouvelR';

export const typographyScheme = {
  dacia: {
    typography: {
      fontSize: 14,
      fontFamily: [primaryFont, 'Read', 'Arial'].join(','),
      h1: {
        fontFamily: primaryFont,
        color: palette.primary.bodyText,
        fontSize: '26px',
        lineHeight: '32px',
        [breakpoints.up('md')]: {
          fontSize: '36px',
          lineHeight: '44px'
        }
      },
      h2: {
        fontFamily: primaryFont,
        color: palette.primary.bodyText,
        fontSize: '18px',
        lineHeight: '22px',
        [breakpoints.up('md')]: {
          fontSize: '24px',
          lineHeight: '28px'
        }
      },
      h3: {
        fontFamily: primaryFont,
        color: palette.primary.bodyText,
        fontSize: '20px',
        lineHeight: '24px'
      },
      h4: {
        fontFamily: 'Read',
        color: palette.primary.bodyText,
        lineHeight: '18px',
        fontSize: '22px'
      },
      h5: {
        fontFamily: primaryFont,
        fontSize: 'inherit'
      },
      h6: {
        fontFamily: primaryFont,
        fontSize: 'inherit'
      },
      subtitle1: {
        fontFamily: 'Read',
        color: palette.primary.bodyText,
        fontSize: '16px',
        lineHeight: '22px',
        [breakpoints.up('md')]: {
          fontSize: '20px',
          lineHeight: '28px'
        }
      },
      subtitle2: {
        fontFamily: primaryFont,
        color: palette.primary.bodyText,
        fontSize: '20px',
        lineHeight: '28px',
        [breakpoints.up('md')]: {
          fontSize: '24px',
          lineHeight: '28px'
        }
      },
      body1: {
        fontFamily: 'Read',
        fontWeight: 700,
        lineHeight: '24px',
        color: palette.primary.bodyText,
        fontSize: '16px'
      },
      body2: {
        fontFamily: 'Read',
        fontWeight: 500,
        lineHeight: '24px',
        color: palette.primary.bodyText,
        fontSize: 'inherit'
      }
    }
  },
  renault: {
    typography: {
      fontSize: 14,
      fontFamily: ['Read', 'Arial'].join(','),
      h1: {
        fontFamily: primaryFont,
        color: palette.primary.bodyText,
        fontSize: '24px',
        lineHeight: '26px',
        fontWeight: 700,
        [breakpoints.up('md')]: {
          fontSize: '28px',
          lineHeight: '30px'
        }
      },
      h2: {
        fontFamily: primaryFont,
        color: palette.primary.bodyText,
        fontSize: '24px',
        lineHeight: '26px',
        fontWeight: 700,
        [breakpoints.up('md')]: {
          fontSize: '28px',
          lineHeight: '30px'
        }
      },
      h3: {
        fontFamily: primaryFont,
        color: palette.primary.bodyText,
        fontSize: '20px',
        lineHeight: '26px',
        // fontWeight: 700,
        [breakpoints.up('md')]: {
          fontSize: '24px',
          lineHeight: '30px'
        }
      },
      h4: {
        fontFamily: primaryFont,
        color: palette.primary.main,
        lineHeight: '24px',
        fontSize: '20px'
      },
      h5: {
        fontFamily: primaryFont,
        fontWeight: 'inherit'
      },
      h6: {
        fontFamily: primaryFont,
        fontSize: 'inherit'
      },
      subtitle1: {
        fontFamily: primaryFont,
        color: palette.primary.bodyText,
        fontSize: '16px',
        lineHeight: '20px',
        [breakpoints.up('md')]: {
          fontSize: '18px',
          lineHeight: '24px'
        }
      },
      subtitle2: {
        fontFamily: primaryFont,
        color: palette.primary.bodyText,
        fontSize: '24px',
        lineHeight: '26px',
        [breakpoints.up('md')]: {
          fontSize: '28px',
          lineHeight: '33px'
        }
      },
      body1: {
        fontFamily: primaryFont,
        fontSize: 16,
        fontWeight: 700,
        lineHeight: '24px',
        color: palette.primary.bodyText
      },
      body2: {
        fontFamily: primaryFont,
        fontWeight: 500,
        lineHeight: '24px',
        color: palette.primary.bodyText,
        fontSize: 'inherit'
      }
    }
  }
};
