import { Box, CircularProgress, Grid } from '@material-ui/core';
import { useEffect, useState, lazy, Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { stepView } from '../commons/gtm-events';
import { useStyles } from './steps.jss';
import { fetchVehicleDetails, setStepNumber, setStepper } from '../actions';

const SectionForm = lazy(() => import('../sections/section-form/sectionForm'));

export default function StepThree(props) {
  const { metaData, actions, config, location, match } = props;
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { vehicleDetails, availability, stepsData, finalBooking } = useSelector(
    (state) => state.carBooking
  );
  const slug = location?.state?.slug;
  const [loading, setLoading] = useState(true);
  const meta = {
    metaTitle: metaData(slug).metaTitle,
    metaDesc: metaData(slug).metaDesc
  };
  const campaign = useSelector((state) => state.trackingDetails?.campaing);

  useEffect(() => {
    if (!finalBooking) dispatch(setStepper(config.stepperShort));
    if (!vehicleDetails) dispatch(fetchVehicleDetails({ vin: match.params.vin }));
    else {
      setLoading(false);
      dispatch(setStepNumber(finalBooking ? 2 : 1)); // if date was chosen (finalBooking), show long stepper and save correct step number
      const sharedStepData = {
        formStepName: 'personal_info',
        dealerName: vehicleDetails?.location?.name,
        dealerId: vehicleDetails?.location?.bir,
        modelName: vehicleDetails?.modelName,
        vehicleId: vehicleDetails?.vehicleId,        
      }

      if(!finalBooking) { // Shorter stepper events
        stepView({
          ...sharedStepData,
          formStepNumber: 'step-1',
          event: 'formSteps',
          shortStepper: true
        })
        return;
      }

      stepView({
        ...sharedStepData,
        formStepNumber: 'step-3',
        event: 'formSteps_3',
        eventAction: 'lead_vn_step3',
        campaign
      });
    }
  }, [
    vehicleDetails,
    history,
    dispatch,
    actions,
    campaign,
    config.stepperShort,
    finalBooking,
    match.params.vin
  ]);

  return (
    <>
      <Helmet
        title={meta.metaTitle}
        meta={[
          {
            name: 'description',
            content: meta.metaDesc
          }
        ]}
      />
      {loading ? (
        <Box width="100%" display="flex" justifyContent="center" marginY={5}>
          <CircularProgress size={100} color="primary" />
        </Box>
      ) : (
        <Grid container className={classes.container}>
          <Suspense
            fallback={
              <Box width="100%" display="flex" justifyContent="center" marginY={5}>
                <CircularProgress size={100} color="primary" />
              </Box>
            }>
            <SectionForm
              vehicleDetails={vehicleDetails}
              availability={availability}
              stepsData={stepsData}
              translations={config}
              slug={slug}
              {...props}
            />
          </Suspense>
        </Grid>
      )}
    </>
  );
}
