import * as actionAndTypes from './index';

const actions = {};

for (const [key, value] of Object.entries(actionAndTypes)) {
  if (typeof value === 'function') {
    actions[key] = value;
  }
}

export default actions;
