/* eslint-disable react-hooks/exhaustive-deps */
import { Box, CircularProgress, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { useEffect, useState, lazy, Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { stepView } from '../commons/gtm-events';
import { useStyles } from './steps.jss';

const CalendarSection = lazy(() => import('../sections/section-calendar/calendarSection'));

export default function StepTwo(props) {
  const { metaData, actions, config, location, match } = props;
  const {
    params: { vin }
  } = match;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { vehicleDetails, vehicleType, finalBooking } = useSelector((state) => state.carBooking);
  const [loading, setLoading] = useState(finalBooking ? false : true);
  const slug = location?.state?.slug;
  const meta = {
    metaTitle: metaData(slug).metaTitle,
    metaDesc: metaData(slug).metaDesc
  };
  const vehicleId = location?.state?.vehicleId;
  const locationId = location?.state?.locationId;
  const errorFromVerification = location?.errorFromVerification;
  const [modelName, setModelName] = useState(null);
  const campaign = useSelector((state) => state.trackingDetails?.campaing);

  useEffect(() => {
    dispatch(actions.fetchVehicleDetails({ vin }));
    dispatch(actions.setStepNumber(1));
  }, []);

  useEffect(() => {
    if (!modelName) return;
    dispatch(actions.saveVehicleModel(modelName));
  }, [modelName]);

  useEffect(() => {
    const locationStateExist =
      location?.state?.vehicleId && vehicleDetails?.vehicleId !== location?.state?.vehicleId;
    if (!vehicleDetails || locationStateExist) return;
    const { vehicleId, locationId } = vehicleDetails;
    if (!finalBooking)
      dispatch(actions.fetchAvailability(vehicleId, locationId)).then(() => setLoading(false));
    setModelName(vehicleDetails?.modelName);
    stepView({
      formStepName: 'date',
      formStepNumber: 'step-2',
      event: 'formSteps_2',
      eventAction: 'lead_vn_step2',
      dealerName: vehicleDetails?.location?.name,
      dealerId: vehicleDetails?.location?.bir,
      modelName: vehicleDetails?.modelName,
      vehicleId: vehicleDetails?.vehicleId,
      campaign
    });
  }, [vehicleDetails]);

  useEffect(() => {
    if (!vehicleId || !locationId)
      dispatch(actions.fetchDictionaries({ vehicleModel: modelName, vehicleType }));
  }, [vehicleId, locationId]);

  return (
    <>
      <Helmet
        title={meta.metaTitle}
        meta={[
          {
            name: 'description',
            content: meta.metaDesc
          }
        ]}
      />

      <Grid container className={classes.container}>
        <div>
          {/*<Headline*/}
          {/*  variant="h2"*/}
          {/*  headline={config.headline}*/}
          {/*  headlinePrimary={modelName}*/}
          {/*/>*/}
          {errorFromVerification && (
            <Box mb={4} align="center">
              <Typography color="error" variant="body2">
                {errorFromVerification}
              </Typography>
            </Box>
          )}
        </div>
        {vehicleDetails && Object.keys(vehicleDetails)?.length > 0 && !loading ? (
          <Suspense
            fallback={
              <Box width="100%" display="flex" justifyContent="center" marginY={5}>
                <CircularProgress size={100} color="primary" />
              </Box>
            }
          >
            <CalendarSection
              translations={config.calendarDetails}
              subheadline={config.subheadline}
              vehicleDetails={vehicleDetails}
              slug={slug}
              {...props}
            />
          </Suspense>
        ) : (
          <Box width="100%" display="flex" justifyContent="center" marginY={5}>
            <CircularProgress size={100} color="primary" />
          </Box>
        )}
      </Grid>
    </>
  );
}
