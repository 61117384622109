import * as actionsAndTypes from '../actions';

const setModelPosition = (models, vehicleModel) => {
  const indexOfSelectedModel = models.findIndex(({ modelName }) => modelName === vehicleModel);
  const firstModel = indexOfSelectedModel === 0 ? models.slice(0, 2).reverse() : models.slice(0, 1);
  const arrayOfModels = indexOfSelectedModel === 0 ? models.slice(2) : models.slice(1);
  const modelsInOrder = arrayOfModels.reduce(
    (accumulator, current) =>
      current.modelName === vehicleModel ? [current, ...accumulator] : [...accumulator, current],
    []
  );
  return [...firstModel, ...modelsInOrder];
};

const initialState = {
  availability: [],
  dictionaries: null,
  vehicleList: [],
  vehicleModel: null,
  vehicleType: null,
  birBranch: null,
  vehicleImages360: [],
  vehicleDetails: null,
  trackingDetails: null,
  step: null,
  completedSteps: {},
  stepsData: {
    step_1: {
      selectedVehicle: null
    },
    step_2: {
      value: null,
      selectedDay: null,
      selectedHour: null,
      reservationDate: null
    },
    step_3: {
      name: null,
      surname: null,
      email: null,
      phone: null,
      userMessage: null,
      smsMarketingOptin: false,
      phoneMarketingOptin: false,
      emailMarketingOptin: false
    },
    step_4: {},
    step_5: {}
  },
  dealers: [],
  models: [],
  electricModels: [],
  selectedDealers: null,
  selectedModels: null,
  hasAnyElectric: null,
  electricChecked: false,
  finalBooking: null,
  otherTypeAvailable: false,
  stepper: [
    'Wybierz auto i dealera',
    'Zaproponuj datę i godzinę',
    'Podaj swoje dane',
    'Sprawdź podsumowanie',
    'Potwierdź kodem SMS'
  ]
};

export default function bookingReducer(state = initialState, action) {
  switch (action.type) {
    case actionsAndTypes.FETCH_DICTIONARIES: {
      const { otherTypeAvailable, dealers, models, hasAnyElectric } = action.payload;
      return {
        ...state,
        dictionaries: action.payload,
        otherTypeAvailable,
        dealers,
        models: state.vehicleModel ? setModelPosition(models, state.vehicleModel) : models,
        hasAnyElectric
      };
    }

    case actionsAndTypes.FETCH_DICTIONARIES_BY_MODEL: {
      const { dealers, hasAnyElectric } = action.payload;
      const dealersByType = state.electricChecked
        ? dealers.filter(({ hasAnyElectric }) => hasAnyElectric)
        : dealers;
      return {
        ...state,
        dealers: dealersByType,
        hasAnyElectric: hasAnyElectric
      };
    }

    case actionsAndTypes.FETCH_DICTIONARIES_BY_DEALERS: {
      const { models, hasAnyElectric } = action.payload;
      const modelsByType = state.electricChecked
        ? models.filter(({ engineType }) => ['HEV', 'ELECTRIC', 'PHEV'].includes(engineType))
        : models;
      return {
        ...state,
        models: state.vehicleModel ? setModelPosition(modelsByType, state.vehicleModel) : models,
        hasAnyElectric: !state.vehicleModel ? hasAnyElectric : state.hasAnyElectric
      };
    }

    case actionsAndTypes.FETCH_VEHICLES: {
      return { ...state, vehicleList: action.payload };
    }

    case actionsAndTypes.FETCH_AVAILABILITY: {
      const availability = action.payload.filter((item) => item.hours.length);
      return { ...state, availability };
    }

    case actionsAndTypes.FETCH_VEHICLE_DETAILS: {
      return { ...state, vehicleDetails: action.payload };
    }

    case actionsAndTypes.FETCH_VEHICLE_IMAGES: {
      return { ...state, vehicleImages360: action.payload };
    }

    case actionsAndTypes.SAVE_VEHICLE_MODEL: {
      return {
        ...state,
        vehicleModel: action.payload
      };
    }

    case actionsAndTypes.SET_SELECTED_DEALERS: {
      return {
        ...state,
        selectedDealers: action.payload
      };
    }

    case actionsAndTypes.SAVE_VEHICLE_TYPE: {
      return { ...state, vehicleType: action.payload };
    }

    case actionsAndTypes.SAVE_BIR_BRANCH: {
      return { ...state, birBranch: action.payload };
    }

    case actionsAndTypes.ELECTRIC_CHECKED: {
      return { ...state, electricChecked: action.payload };
    }

    case actionsAndTypes.SAVE_TRACKING_DETAILS: {
      return {
        ...state,
        trackingDetails: Object.values(action.payload).length
          ? {
              ...action.payload
            }
          : null
      };
    }

    case actionsAndTypes.SET_STEP_NUMBER: {
      return {
        ...state,
        step: action.payload,
        completedSteps: {
          ...state.completedSteps,
          ...{ 0: true, [action.payload]: true }
        }
      };
    }

    case actionsAndTypes.SET_STEP_DATA: {
      return {
        ...state,
        stepsData: {
          ...state.stepsData,
          ...action.payload
        }
      };
    }

    case actionsAndTypes.CLEAN_UP_STEPS: {
      return { ...initialState };
    }

    case actionsAndTypes.SET_FINAL_BOOKING: {
      return {
        ...state,
        finalBooking: action.payload
      };
    }

    case actionsAndTypes.REMOVE_COMPLETED_STEPS: {
      return {
        ...state,
        completedSteps: {}
      };
    }
    case actionsAndTypes.SET_STEPPER: {
      return { ...state, stepper: action.payload };
    }

    default:
      return state;
  }
}
