/* HOMEPAGE DATA */

const BRAND_NAME = process.env.REACT_APP_BRAND;

export const FETCH_DICTIONARIES = 'FETCH_DICTIONARIES';
export const FETCH_DICTIONARIES_BY_MODEL = 'FETCH_DICTIONARIES_BY_MODEL';
export const FETCH_DICTIONARIES_BY_DEALERS = 'FETCH_DICTIONARIES_BY_DEALERS';
export const FETCH_VEHICLES = 'FETCH_VEHICLES';
export const FETCH_AVAILABILITY = 'FETCH_AVAILABILITY';
export const FETCH_VEHICLE_DETAILS = 'FETCH_VEHICLE_DETAILS';
export const FETCH_VEHICLE_IMAGES = 'FETCH_VEHICLE_IMAGES';
export const SAVE_VEHICLE_MODEL = 'SAVE_VEHICLE_MODEL';
export const SAVE_VEHICLE_TYPE = 'SAVE_VEHICLE_TYPE';
export const SAVE_BIR_BRANCH = 'SAVE_BIR_BRANCH';
export const SAVE_TRACKING_DETAILS = 'SAVE_TRACKING_DETAILS';
export const SET_STEP_NUMBER = 'SET_STEP_NUMBER';
export const SET_STEP_DATA = 'SET_STEP_DATA';
export const SET_SELECTED_DEALERS = 'SET_SELECTED_DEALERS';
export const ELECTRIC_CHECKED = 'ELECTRIC_CHECKED';
export const CLEAN_UP_STEPS = 'CLEAN_UP_STEPS';
export const SET_FINAL_BOOKING = 'SET_FINAL_BOOKING';
export const REMOVE_COMPLETED_STEPS = 'REMOVE_COMPLETED_STEPS';
export const SET_STEPPER = 'SET_STEPPER';

export function fetchDictionaries({ vehicleType, vehicleModel }) {
  return (dispatch, getState, { axiosInstances, history }) => {
    const dealerGroup = getState().carBooking.birBranch;
    const onlyElectric = getState().carBooking.electricChecked;
    const pathType = history.location.pathname.split('/');
    if (dealerGroup && pathType.length === 4) {
      const dealerBranch = pathType[3] === 'osobowe';
      if (dealerBranch)
        history.push({
          pathname: `/dealer/${dealerGroup}`
        });
    } // SEO redirect
    if (vehicleType)
      dispatch({
        type: SAVE_VEHICLE_TYPE,
        payload: vehicleType
      });
    return axiosInstances
      .getDictionariesByCriteria({
        brand: BRAND_NAME,
        type: getState().carBooking.vehicleType,
        ...(dealerGroup && { birBranch: dealerGroup }),
        ...(onlyElectric && { onlyElectric })
      })
      .then(({ status, data: { data } }) => {
        if (status === 200) {
          if (vehicleModel) {
            const modelName = data.models?.find((item) => item.slug === vehicleModel)?.modelName;
            if (!modelName && vehicleType && BRAND_NAME !== 'dacia')
              history.push({
                pathname: `/gama/${vehicleType}`
              });
            dispatch({
              type: SAVE_VEHICLE_MODEL,
              payload: modelName
            });
          }
          dispatch({
            type: FETCH_DICTIONARIES,
            payload: data
          });
        } else {
          throw new Error(data.ErrorMessage);
        }
        return data;
      });
  };
}

export function fetchDictionariesByDealers({ bir, place, onlyElectric }) {
  return (dispatch, getState, { axiosInstances }) => {
    const dealerGroup = getState().carBooking.birBranch;
    return axiosInstances
      .getDictionariesByCriteria({
        brand: BRAND_NAME,
        type: getState().carBooking.vehicleType,
        ...(dealerGroup && { birBranch: dealerGroup }),
        ...(place && {
          latitude: place.latitude,
          longitude: place.longitude
        }),
        ...(onlyElectric && { onlyElectric }),
        bir
      })
      .then(({ status, data: { data } }) => {
        const { models, hasAnyElectric } = data;
        if (status === 200) {
          dispatch({
            type: FETCH_DICTIONARIES_BY_DEALERS,
            payload: { models, hasAnyElectric }
          });
        } else {
          throw new Error(data.ErrorMessage);
        }
      });
  };
}

export function fetchDictionariesByModel({ model, place, onlyElectric }) {
  return (dispatch, getState, { axiosInstances }) => {
    const dealerGroup = getState().carBooking.birBranch;
    return axiosInstances
      .getDictionariesByCriteria({
        brand: BRAND_NAME,
        type: getState().carBooking.vehicleType,
        ...(dealerGroup && { birBranch: dealerGroup }),
        ...(place && {
          latitude: place.latitude,
          longitude: place.longitude
        }),
        ...(onlyElectric && { onlyElectric }),
        model
      })
      .then(({ status, data: { data } }) => {
        const { dealers, hasAnyElectric } = data;
        if (status === 200) {
          dispatch({
            type: FETCH_DICTIONARIES_BY_MODEL,
            payload: { dealers, hasAnyElectric }
          });
        } else {
          throw new Error(data.ErrorMessage);
        }
      });
  };
}

export function fetchVehicles(payload) {
  return (dispatch, getState, { axiosInstances, history }) => {
    const onlyElectric = payload?.onlyElectric || getState().carBooking.electricChecked;
    const bir = payload?.bir || getState().carBooking.selectedDealers;
    const type = payload?.vehicleType || getState().carBooking.vehicleType;
    const vehicleModel = payload?.vehicleModel || getState().carBooking.vehicleModel;
    const place = payload?.place || getState().carBooking.place;
    const { birBranch } = getState().carBooking;
    const { pageIndex, pageSize } = payload;
    return axiosInstances
      .getVehicles({
        brand: BRAND_NAME,
        type,
        ...(birBranch && { birBranch }),
        ...(place && {
          latitude: place.latitude,
          longitude: place.longitude
        }),
        ...(vehicleModel && { model: vehicleModel }),
        ...(bir && { bir }),
        ...(onlyElectric && { onlyElectric }),
        pageIndex: pageIndex || 0,
        pageSize: pageSize || 10
      })
      .then((res) => {
        const {
          status,
          data: { data }
        } = res;
        if (!data.vehicles.length)
          history.push({
            pathname: `/`
          });
        if (status === 200) {
          dispatch({
            type: FETCH_VEHICLES,
            payload: data
          });
        } else {
          throw new Error(res.data.ErrorMessage);
        }
        return data;
      });
  };
}

export function fetchAvailability(vehicleId, locationId) {
  return (dispatch, _, { axiosInstances }) => {
    return axiosInstances
      .getAvailability(vehicleId, locationId)
      .then((res) => {
        const {
          status,
          data: { data }
        } = res;
        if (status === 200) {
          dispatch({
            type: FETCH_AVAILABILITY,
            payload: data
          });
        } else {
          throw new Error(res.data.ErrorMessage);
        }
        return data;
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function fetchVehicleDetails({ vin }) {
  const BRAND_NAME = process.env.REACT_APP_BRAND;
  return (dispatch, getState, { axiosInstances, history }) => {
    return axiosInstances
      .getVehicleDetails({
        vin: vin,
        brand: BRAND_NAME
      })
      .then((res) => {
        const {
          status,
          data: { data }
        } = res;
        if (status === 200) {
          dispatch({
            type: FETCH_VEHICLE_DETAILS,
            payload: data
          });
        }
        return data;
      })
      .catch((error) => {
        history.push({
          pathname: '/'
        });
      });
  };
}

export function getVerificationCode({ phone, brandName }) {
  return (dispatch, getState, { axiosInstances }) => {
    return axiosInstances.sendVerificationCode({
      phone,
      brandName
    });
  };
}

export function getVerificationCodeValidation({ phone, verificationCode }) {
  return (dispatch, getState, { axiosInstances }) => {
    return axiosInstances.validateVerificationCode({
      phone: phone,
      verificationCode: verificationCode
    });
  };
}

export function createReservationLead(data) {
  return (dispatch, getState, { axiosInstances }) => {
    return axiosInstances.createReservation(data);
  };
}

export function createSimpleReservationLead(data) {
  return (dispatch, getState, { axiosInstances }) => {
    return axiosInstances.createSimpleReservation(data);
  };
}

// Save into app context

export const saveVehicleModel = (payload) => ({
  type: SAVE_VEHICLE_MODEL,
  payload
});
export const saveVehicleType = (payload) => ({
  type: SAVE_VEHICLE_TYPE,
  payload
});
export const saveSelectedDealers = (payload) => ({
  type: SET_SELECTED_DEALERS,
  payload
});
export const saveBirBranch = (payload) => ({
  type: SAVE_BIR_BRANCH,
  payload
});
export const saveElectricChecked = (payload) => ({
  type: ELECTRIC_CHECKED,
  payload
});
export const setStepNumber = (payload) => ({
  type: SET_STEP_NUMBER,
  payload
});
export const setStepData = (payload) => ({
  type: SET_STEP_DATA,
  payload
});
export const cleanUpSteps = () => ({
  type: CLEAN_UP_STEPS
});
export const setFinalBooking = (payload) => ({
  type: SET_FINAL_BOOKING,
  payload
});
export const removeVehicleDetails = () => ({
  type: FETCH_VEHICLE_DETAILS,
  payload: null
});
export const removeCompletedSteps = () => ({
  type: REMOVE_COMPLETED_STEPS
});

export const saveTrackingDetails = (payload) => ({
  type: SAVE_TRACKING_DETAILS,
  payload: payload
});
export const setStepper = (payload) => ({
  type: SET_STEPPER,
  payload: payload
});
