import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ spacing, themeName, breakpoints }) => ({
  noBannerContainer: {
    width: '100%',
    maxWidth: '1280px',
    margin: '0 auto',
    justifyContent: 'center',
    padding: spacing(0, 2),
    marginTop: spacing(4),
    [breakpoints.up('lg')]: {
      padding: 0
    }
  },
  infoBox: {
    backgroundColor: '#72C293',
    padding: spacing(3),
    marginBottom: spacing(4),
    [breakpoints.up('md')]: {
      padding: spacing(4, 0)
    },
    '& div': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      [breakpoints.up('sm')]: {
        flexDirection: 'row'
      }
    },
    '& p': {
      color: '#fff',
      fontWeight: 700,
      textAlign: 'left',
      marginTop: spacing(2),
      [breakpoints.up('sm')]: {
        marginTop: 0
      }
    }
  },
  img: {
    width: 'auto',
    maxHeight: '320px',
    maxWidth: '100%'
  },
  actionButtons: {
    display: 'grid',
    justifyContent: 'center',
    gridTemplateColumns: '1fr',
    width: '100%',
    gridGap: spacing(3),
    marginBottom: spacing(8),
    [breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))'
    }
  },
  hide: {
    display: 'none'
  },
  subheadline: {
    ...(themeName === 'dacia' && {
      textTransform: 'none'
    })
  }
}));
