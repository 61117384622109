const modelsData = [
  {
    key: 'clio',
    metaTitle: 'Jazda próbna - Renault Clio - umów się online!',
    metaDesc:
      'Poczuj komfort jazdy w nowoczesnych wnętrzach. Zaplanuj swoją podróż w technologii Clio! Umów jazdę próbną już dziś!'
  },
  {
    key: 'captur',
    metaTitle: 'Jazda próbna - Renault Captur - umów się online!',
    metaDesc:
      'Poczuj komfort innowacyjności w najmniejszym detalu. Przetestuj Renault Captur już dziś! Umów jazdę próbną online. '
  },
  {
    key: 'zoe',
    metaTitle: 'Jazda próbna - Renault Zoe E-Tech - umów się online!',
    metaDesc:
      'Wypróbuj elektryzujące możliwości ekologicznego Zoe E-Tech osobiście. Umów jazdę próbną już dziś!'
  },
  {
    key: 'clio-hybryda',
    metaTitle: 'Jazda próbna - Renault Clio Hybryda - umów się online!',
    metaDesc: 'Przetestuj osobiście ekologiczną hybrydę Clio E-Tech. Umów jazdę próbną już dziś!'
  },
  {
    key: 'captur-hybryda-plug-in',
    metaTitle: 'Jazda próbna - Renault Captur Hybryda - umów się online!',
    metaDesc:
      'Wypróbuj technologię Formuły 1 w hybrydowym wydaniu Renault Captur Hybryd Plug-in. Umów jazdę próbną już dziś!'
  },
  {
    key: 'nowy-megane',
    metaTitle: 'Jazda próbna - Renault Megane - umów się online!',
    metaDesc: 'Przetestuj kompaktowy hatchback o niebanalnym wnętrzu. Umów jazdę próbną już dziś! '
  },
  {
    key: 'nowy-megane-grandtour-hybryda-plug-in',
    metaTitle: 'Jazda próbna - Megane Grandtour Hybryda - umów się online!',
    metaDesc:
      'Przetestuj osobiście ekologiczną hybrydę Megane Grandtour Plug-in. Umów jazdę próbną już dziś!'
  },
  {
    key: 'nowy-megane-grandcoupe',
    metaTitle: 'Jazda próbna - Renault Megane GrandCoupé - umów się online!',
    metaDesc: 'Przetestuj na własnej skórze komfort jazdy z Megane  GrandCoupé. Umów jazdę próbną!'
  },
  {
    key: 'grand-scenic',
    metaTitle: 'Jazda próbna - Renault Grand Scenic - umów się online!',
    metaDesc: 'Przetestuj na własnej skórze komfort jazdy z Megane  GrandCoupé. Umów jazdę próbną!'
  },
  {
    key: 'kadjar',
    metaTitle: 'Jazda próbna - Renault Kadjar - umów się online!',
    metaDesc:
      'Poczuj dreszczyk emocji wyruszając w podróż terenowym SUVem Renault. Umów jazdę próbną już dziś! '
  },
  {
    key: 'arkana',
    metaTitle: 'Jazda próbna - Renault Arkana - umów się online!',
    metaDesc: 'Przetestuj przestronnego SUVa o sportowym charakterze. Umów jazdę próbną już dziś!'
  },
  {
    key: 'nowy-talisman',
    metaTitle: 'Jazda próbna - Renault Talisman - umów się online!',
    metaDesc:
      'Przetestuj modułowy i przestronny sedan w każdej sytuacji. Sprawdź jego możliwości już dziś. Umów jazdę próbną online!'
  },
  {
    key: 'nowy-talisman-grandtour',
    metaTitle: 'Jazda próbna - Renault Talisman Garndtour - umów się online!',
    metaDesc:
      'Wypróbuj przestronne kombi dla całej rodziny. Przetestuj go sam! Umów jazdę próbną już dziś!'
  },
  {
    key: 'koleos',
    metaTitle: 'Jazda próbna - Renault Koleos - umów się online!',
    metaDesc:
      'Sprawdź możliwości niezawodnego SUVa o przestronnej kabinie. Umów jazdę próbną online!'
  },
  {
    key: 'espace',
    metaTitle: 'Jazda próbna - Renault Espace - umów się online!',
    metaDesc:
      'Sprawdź co może zaoferować Ci niezawodny crossover z najwyższej półki. Odkryj przyjemność jazdy z Renault! Umów jazdę próbną już dziś!'
  },
  {
    key: 'trafic-osobowy',
    metaTitle: 'Jazda próbna - Renault Trafic osobowe - umów się online!',
    metaDesc:
      'Sprawdź wygodne i przestronne wnętrza zamknięte w dynamicznej sylwetce. Umów jazdę próbą online!'
  },
  {
    key: 'trafic-spaceclass',
    metaTitle: 'Jazda próbna - Renault Trafic Spaceclass - umów się online!',
    metaDesc:
      'Wypróbuj wygodne i nowoczesne wnętrza zamknięte w solidnej sylwetce. Umów jazdę próbą jednym kliknięciem!'
  },
  {
    key: 'trafic',
    metaTitle: 'Jazda próbna - Renault Trafic - umów się online!',
    metaDesc:
      'Postaw na wszechstronność możliwości zamkniętą w prostej formie. Sprawdź jak Trafic może pomóc Ci w codziennych wyzwaniach! Umów jazdę online!'
  },
  {
    key: 'kangoo-express',
    metaTitle: 'Jazda próbna - Renault Kangoo Express - umów się online!',
    metaDesc:
      'Przetestuj możliwości przestronnej przestrzeni ładunkowej Kangoo Express! Umów jazdę próbną już dziś!'
  },
  {
    key: 'kangoo-ze',
    metaTitle: 'Jazda próbna - Renault Kangoo E-Tech. Umów się online!',
    metaDesc:
      'Sprawdź możliwości cichego i w 100% ekologicznego kombivana Renault E-Tech. Umów jazdę próbną online!'
  },
  {
    key: 'master',
    metaTitle: 'Jazda próbna - Renault Master - umów się online!',
    metaDesc:
      'Postaw na prostotę i nowoczesność z dbałością o ekologię i świetną ekonomię jazdy. Wypróbuj dostawcze Renault już dziś. Umów jazdę próbną online! '
  },
  {
    key: 'master-ze',
    metaTitle: 'Jazda próbna - Renault Master E-Tech. Umów się online!',
    metaDesc:
      'Wypróbuj elektryczny miejski furgon do zadań specjalnych. Umów jazdę próbna online już dziś!'
  },
  {
    key: 'duster',
    metaTitle: 'Jazda próbna Dacią Duster. Umów się online!',
    metaDesc:
      'Skorzystaj z okazji i wypróbuj wygodne wnętrza i nowoczesne technologie zamknięte w solidnej sylwetce SUVa. Umów jazdę próbną już dziś!'
  },
  {
    key: 'nowe-sandero',
    metaTitle: 'Jazda próbna Dacią Sandero. Umów się online!',
    metaDesc:
      'Sprawdź jak zwinna sylwetka i technologiczne udogodnienia sprawdzają się w codziennych podróżach. Umów jazdę próbną online!'
  },
  {
    key: 'nowe-sandero-stepway',
    metaTitle: 'Jazda próbna Dacią Sandero Stepway. Umów się online!',
    metaDesc:
      'Postaw na solidną sylwetkę i wszechstronność możliwości. Odkryj co kryje w sobie podróż crossoverem Dacia. Umów jazdę próbną już dziś!'
  },
  {
    key: 'nowy-logan',
    metaTitle: 'Jazda próbna Dacią Logan. Umów się online!',
    metaDesc:
      'Poczuj komfort jazdy rodzinnym SUVem z najwyższej półki. Sprawdź jego możliwości już dziś! Umów jazdę próbną online!'
  },
  {
    key: 'lodgy',
    metaTitle: 'Jazda próbna Dacią Lodgy. Umów się online!',
    metaDesc: 'Przetestuj na własnej skórze komfort jazdy z Megane  GrandCoupé. Umów jazdę próbną!'
  },
  {
    key: 'spring',
    metaTitle: 'Jazda próbna Dacią Spring. Umów się online!',
    metaDesc:
      'Poczuj powiew wiosny dzięki komfortowi podróży elektrycznym samochodem miejskim. Umów jazdę próbną już dziś!'
  }
];

const defaultModels =
  process.env.REACT_APP_BRAND === 'dacia'
    ? 'Czekają na Ciebie Duster, Sandero, Lodgy lub ekologiczna Dacia Spring.'
    : 'Czekają na Ciebie Clio, Master, Captur lub ekologiczna hybryda';

const firstLetter = process.env.REACT_APP_BRAND.charAt(0);
const remainigLetters = process.env.REACT_APP_BRAND.slice(1);
const capitalizedBrandName = `${firstLetter?.toUpperCase()}${remainigLetters}`;

const dataDefault = {
  metaTitle: `Jazda próbna ${capitalizedBrandName} - umów się online!`,
  metaDesc: `Wybierz model ${capitalizedBrandName} i umów się na jazdę próbną online! ${defaultModels}`
};

export const metaData = (model) => {
  const isModelExisting = modelsData.find((item) => item.key === model);
  return isModelExisting ? isModelExisting : dataDefault;
};

export const metaDealerData = (type, dealer) => {
  if (type === 'dostawcze')
    return {
      metaTitle: `Jazda próbna samochodem dostawczym | ${dealer?.toUpperCase()} | ${capitalizedBrandName} PL`,
      metaDesc: `Wybierz model i wersję samochodu dostawczego Renault i umów się online na jazdę próbną u naszego dealera - ${dealer?.toUpperCase()}. Testuj nowe samochody dostawcze w wybranej lokalizacji.`
    };
  return {
    metaTitle: `Jazda próbna - Umów się na jazdę testową | ${dealer?.toUpperCase()} | ${capitalizedBrandName} PL`,
    metaDesc: `Wybierz model samochodu osobowego Renault i umów się online na jazdę próbną u naszego dealera - ${dealer?.toUpperCase()}. Testuj nowe hybrydy, samochody elektryczne i benzynowe w wybranej lokalizacji.`
  };
};
