import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ spacing }) => ({
  icon: {
    color: 'inherit',
    fill: ({ color }) => color,
    display: 'inline-block',
    verticalAlign: 'middle',
    marginRight: ({ marginRight }) => spacing(marginRight),
    marginLeft: ({ marginLeft }) => spacing(marginLeft)
  },
  muiIconWrapper: {}
}));
