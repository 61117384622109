export const pageView = ({ pageName }) => {
  window.dataLayer.push({
    brand: process.env.REACT_APP_BRAND,
    countryCode: 'PL',
    languageCode: 'pl',
    businessType: 'new-car',
    pageName: pageName,
    pageType: 'homepage'
  });
};

export const stepView = ({
  formStepName,
  formStepNumber,
  shortStepper,
  event,
  eventAction,
  eventCategory = 'Leadform Steps',
  campaign,
  dealerName,
  dealerId,
  modelName,
  vehicleId,
  modelChooseType = null
}) => {
  if (shortStepper) {
    window.dataLayer.push({
      brand: process.env.REACT_APP_BRAND,
      countryCode: 'PL',
      languageCode: 'pl',
      formType: 'test_drive_request',
      formCategory: 'lead_newcar',
      formStepName: formStepName,
      formStepNumber: formStepNumber,
      event: 'formSteps',
      pageType: 'form',
      businessType: 'new-car',
      dealerName: dealerName || '',
      dealerId: dealerId || '',
      vehicleModel: modelName || '',
      vehicleId: vehicleId || '',
      formName: 'ML-BATD-ONLINE'
    });
    return;
  }

  window.dataLayer.push({
    formType: process.env.REACT_APP_BRAND === 'dacia' ? 'book-a-test-drive' : 'test_drive_booking',
    formCategory: 'lead_vn',
    formStepName,
    formStepNumber,
    event,
    formName: process.env.REACT_APP_BRAND === 'dacia' ? 'book-a-test-drive' : 'ML-BATD-ONLINE',
    pageType: 'form',
    businessType: 'new-car',
    dealerName: dealerName == null ? '' : dealerName,
    dealerId: dealerId == null ? '' : dealerId,
    modelName: modelName == null ? '' : modelName,
    ...(modelChooseType && {
      modelChooseType: modelChooseType == null ? '' : modelChooseType
    }),
    campaing: campaign == null ? '' : campaign,
    eventCategory,
    eventAction,
    eventLabel: process.env.REACT_APP_BRAND === 'dacia' ? 'book-a-test-drive' : 'ML-BATD-ONLINE'
  });
};

export const printEvent = ({
  dealerId,
  vehicleId,
  vehicleModel,
  dealerName,
  pageName,
  brochureName
}) => {
  window.dataLayer.push({
    brand: 'renault',
    countryCode: 'PL',
    languageCode: 'pl',
    brochureName: brochureName || '',
    event: 'download',
    businessType: 'after-sales',
    pageName: pageName || '',
    pageType: 'form',
    dealerName: dealerName || '',
    dealerId: dealerId || '',
    vehicleModel: vehicleModel || '',
    vehicleId: vehicleId || ''
  });
};

export const shorterThankYouView = ({ dealerName, dealerId, vehicleModel, vehicleId, leadId }) => {
  window.dataLayer.push({
    brand: process.env.REACT_APP_BRAND,
    countryCode: 'PL',
    languageCode: 'pl',
    formType: 'test_drive_request',
    formCategory: 'lead_newcar',
    leadId: leadId || '',
    event: 'formCompleted',
    pageType: 'form',
    businessType: 'new-car',
    dealerName: dealerName || '',
    dealerId: dealerId || '',
    vehicleModel: vehicleModel || '',
    vehicleId: vehicleId || '',
    formName: 'ML-BATD-ONLINE'
  });
  return;
};

export const stepperShortOpen = ({ dealerName, dealerId, vehicleModel, vehicleId }) => {
  window.dataLayer.push({
    brand: process.env.REACT_APP_BRAND,
    countryCode: 'PL',
    languageCode: 'pl',
    formType: 'test_drive_request',
    formCategory: 'lead_newcar',
    formStepName: 'start',
    formStepNumber: 'step-0',
    event: 'formOpen',
    pageType: 'form',
    businessType: 'new-car',
    dealerName: dealerName || '',
    dealerId: dealerId || '',
    vehicleModel: vehicleModel || '',
    vehicleId: vehicleId || '',
    formName: process.env.REACT_APP_BRAND === 'dacia' ? 'book-a-test-drive' : 'ML-BATD-ONLINE'
  });
};
