import { metaData, metaDealerData } from './meta-data';

export const HTML_BASE_NAME = `${process.env.PUBLIC_URL.replace(/\/$/, '')}` || '';

export const ROUTES = {
  dacia: [
    {
      name: 'homepage',
      path: '/',
      componentType: 'StepOne',
      translations: 'StepOne',
      metaData,
      metaDealerData
    },
    {
      name: 'homepage',
      path: '/dealer/:dealer',
      componentType: 'StepOne',
      translations: 'StepOne',
      metaData,
      metaDealerData
    },
    {
      name: 'homepage',
      path: '/dealer/:dealer/:model',
      componentType: 'StepOne',
      translations: 'StepOne',
      metaData,
      metaDealerData
    },
    {
      name: 'homepage',
      path: '/gama/:model',
      componentType: 'StepOne',
      translations: 'StepOne',
      metaData,
      metaDealerData
    },
    {
      pageType: 'calendar',
      path: '/kalendarz/:vin',
      componentType: 'StepTwo',
      translations: 'calendarPage',
      metaData
    },
    {
      pageType: 'form',
      path: '/formularz/:vin',
      componentType: 'StepThree',
      translations: 'formPage',
      metaData
    },
    {
      pageType: 'summary',
      path: '/podsumowanie/:vin',
      componentType: 'StepFour',
      translations: 'summaryPage',
      metaData
    },
    {
      pageType: 'verification',
      path: '/weryfikacja/:vin',
      componentType: 'StepFive',
      translations: 'verificationPage',
      metaData
    }
    // {
    //   pageType: "thanks",
    //   path: "/formularz-wyslany",
    //   componentType: "ThanksPage",
    //   translations: "thanksPage",
    //   metaData: {
    //     metaTitle: "Dziękujemy za dokonanie rezerwacji!",
    //     metaDesc: "",
    //   },
    // },
  ],
  renault: [
    {
      pageType: 'homepage',
      path: '/',
      componentType: 'StepOne',
      translations: 'homePage',
      metaData,
      metaDealerData
    },
    {
      pageType: 'homepage',
      path: '/dealer/:dealer',
      componentType: 'StepOne',
      translations: 'homePage',
      metaData,
      metaDealerData
    },
    {
      pageType: 'homepage',
      path: '/dealer/:dealer/:type(osobowe|dostawcze)',
      componentType: 'StepOne',
      translations: 'homePage',
      metaData,
      metaDealerData
    },
    {
      pageType: 'homepage',
      path: '/dealer/:dealer/:type(osobowe|dostawcze)/:model',
      componentType: 'StepOne',
      translations: 'homePage',
      metaData,
      metaDealerData
    },
    {
      pageType: 'homepage',
      path: '/gama/:type(osobowe|dostawcze)/:onlyElectric(elektryczne-hybrydowe)',
      componentType: 'StepOne',
      translations: 'homePage',
      metaData,
      metaDealerData
    },
    {
      pageType: 'homepage',
      path: '/gama/:type(osobowe|dostawcze)',
      componentType: 'StepOne',
      translations: 'homePage',
      metaData,
      metaDealerData
    },
    {
      pageType: 'homepage',
      path: '/gama/:type(osobowe|dostawcze)/:model',
      componentType: 'StepOne',
      translations: 'homePage',
      metaData,
      metaDealerData
    },
    {
      pageType: 'calendar',
      path: '/kalendarz/:vin',
      componentType: 'StepTwo',
      translations: 'calendarPage',
      metaData
    },
    {
      pageType: 'form',
      path: '/formularz/:vin',
      componentType: 'StepThree',
      translations: 'formPage',
      metaData
    },
    {
      pageType: 'summary',
      path: '/podsumowanie/:vin',
      componentType: 'StepFour',
      translations: 'summaryPage',
      metaData
    },
    {
      pageType: 'verification',
      path: '/weryfikacja/:vin',
      componentType: 'StepFive',
      translations: 'verificationPage',
      metaData
    }
    // {
    //   pageType: "thanks",
    //   path: "/formularz-wyslany",
    //   componentType: "ThanksPage",
    //   translations: "thanksPage",
    //   metaData: {
    //     metaTitle: "Dziękujemy za dokonanie rezerwacji!",
    //     metaDesc: "",
    //   },
    // },
  ]
};
