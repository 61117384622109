export const colorScheme = {
  dacia: {
    background: {
      light: '#FFFFFF',
      gray: '#F2F2F2',
      label: '#FF671B',
      icon: '#153450'
    },
    primary: {
      main: '#646B52',
      contrastText: '#ffffff',
      bodyText: '#000000'
    },
    secondary: {
      main: '#646B52',
      contrastText: '#ffffff'
    },
    neutral: {
      light: '#f2f2f2',
      medium: '#cccccc',
      base: '#777777',
      dark: '#333333'
    },
    common: {
      modelCarouselBorder: '#646B52',
      iconColor: '#646B52',
      iconColorDisabled: '#000000',
      iconCarListReservationHoverColor: '#ffffff',
      iconMobile330Viewer: '#ffffff',
      calendarDateHover: '#646B52',
      calendarDateHoverBorder: '#646B52',
      calendarDateHoverText: '#fff',
      calendarDateChoosen: '#fff',
      calendarDateSundays: '#F2F2F2',
      calendarDateToday: '#000000',
      calendarBorder: '#CCCCCC',
      formLabel: '#777777',
      formLabelFocused: '#0d21cf',
      marketingText: '#777777',
      black: '#000000',
      gray: '#EEEEEE',
      customCheckbox: '#CCCCCC',
      link: '#777777',
      checkboxBox: '#EC6528',
      stepperActive: '#646B52',
      stepperActiveText: '#fff',
      stepperCompleted: '#fff',
      stepperCompletedText: '#646B52',
      adornedEnd: '#fff',
      iconSearch: '#646B52',
      engineChip: '#EC6528',
      iconGeolocalization: '#646B52'
    }
  },
  renault: {
    background: {
      light: '#FFFFFF',
      gray: '#F2F2F2',
      label: '#FF671B',
      icon: '#153450'
    },
    primary: {
      main: '#000000',
      contrastText: '#ffffff',
      bodyText: '#000000'
    },
    secondary: {
      main: '#EFDF00',
      contrastText: '#ffffff'
    },
    neutral: {
      light: '#f2f2f2',
      medium: '#cccccc',
      base: '#777777',
      dark: '#333333'
    },
    common: {
      superPrimary: '#F8EB4C',
      modelCarouselBorder: '#EFDF00',
      iconColor: '#000000',
      iconColorDisabled: '#8C8C8B',
      iconCarListReservationHoverColor: '#000000',
      iconMobile330Viewer: '#EFDF00',
      calendarDateHover: '#EFDF00',
      calendarDateHoverBorder: '#EFDF00',
      calendarDateHoverText: '#000',
      calendarDateChoosen: '#000000',
      calendarDateSundays: '#EEEFEC',
      calendarDateToday: '#8C8C8B',
      calendarBorder: '#CCCCCC',
      marketingText: '#656666',
      formLabel: '#8C8C8B',
      formLabelFocused: '#EFDF00',
      black: '#000000',
      gray: '#EEEEEE',
      darkGray: '#888B8D',
      customCheckbox: '#000000',
      link: '#0000EE',
      chip: '#2B9AF7',
      checkboxBox: '#2B9AF7',
      stepperActive: '#EFDF00',
      stepperActiveText: '#000',
      stepperCompleted: '#D8D8D8',
      stepperCompletedText: '#888B8D',
      accordionBorder: '#D9D9D6',
      adornedEnd: '#000',
      iconSearch: '#fff',
      iconGeolocalization: '#000'
    }
  }
};
