import Typography from '@material-ui/core/Typography';
import { useStyles } from './paragraph.jss';

export default function Paragraph({
  children,
  variant = 'body1',
  component = 'p',
  color,
  fontWeight = 500,
  marginTop = 0,
  marginBottom = 0,
  xsMarginTop = marginTop,
  xsMarginBottom = marginBottom,
  textAlign = 'center',
  xsTextAlign = textAlign,
  className
}) {
  const classes = useStyles({
    fontWeight,
    marginTop,
    marginBottom,
    xsMarginTop,
    xsMarginBottom,
    textAlign,
    xsTextAlign
  });

  return (
    <Typography
      classes={{ root: classes.paragraph }}
      className={className}
      variant={variant}
      component={component}
      color={color}
    >
      {children}
    </Typography>
  );
}
