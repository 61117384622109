import Typography from '@material-ui/core/Typography';
import { useStyles } from './headline.jss';

export default function Headline({
  headline,
  headlinePrimary,
  component,
  variant = 'h2',
  spaceBeetwen = false,
  gutterBottom = true,
  fontWeight = 700,
  textAlign = 'center',
  xsTextAlign = textAlign,
  className
}) {
  const classes = useStyles({
    fontWeight,
    textAlign,
    xsTextAlign
  });

  return (
    <Typography
      classes={{ root: classes.headline }}
      className={className}
      variant={variant}
      component={component}
      gutterBottom={gutterBottom}
    >
      {headline} {spaceBeetwen && <br />}
      {headlinePrimary && <span>{headlinePrimary}</span>}
    </Typography>
  );
}
