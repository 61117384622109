import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  container: {
    marginTop: spacing(4),
    padding: spacing(0, 2),
    maxWidth: 1061,
    [breakpoints.up('md')]: {
      padding: 0
    }
  }
}));
