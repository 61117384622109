import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import carBooking from './bookingReducer';

const reducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    carBooking
  });

export default reducer;
