import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  paragraph: {
    fontWeight: ({ fontWeight }) => fontWeight,
    textAlign: ({ xsTextAlign }) => xsTextAlign,
    marginTop: ({ xsMarginTop }) => theme.spacing(xsMarginTop),
    marginBottom: ({ xsMarginBottom }) => theme.spacing(xsMarginBottom),
    [theme.breakpoints.up('sm')]: {
      textAlign: ({ textAlign }) => textAlign,
      marginBottom: ({ marginBottom }) => theme.spacing(marginBottom),
      marginTop: ({ marginTop }) => theme.spacing(marginTop)
    }
  }
}));
