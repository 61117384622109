export const organization = {
  '@context': 'http://schema.org',
  '@type': 'Organization',
  name: 'Renault Polska',
  url: 'https://www.renault.pl/',
  logo: 'https://www.renault.pl/logo.png',
  sameAs: ['https://www.facebook.com/RenaultPolska'],
  contactPoint: {
    '@type': 'ContactPoint',
    email: 'kontakt@renault.pl',
    telephone: '+48 800 900 803',
    contactType: 'customer support'
  }
};
