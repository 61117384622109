const windowUri = window.location.origin;
const BRAND_NAME = process.env.REACT_APP_BRAND;

export const publicUrl = (path) => `${process.env.PUBLIC_URL || ''}${path}`;
export const API_BASE_URL =
  process.env.NODE_ENV === 'production' && !process.env.REACT_APP_API_URL
    ? windowUri
    : process.env.REACT_APP_API_URL;

export const GET_VEHICLES = '/api/booking/getvehicles';
export const GET_DICTIONARIES = `/api/booking/getdictionaries/${BRAND_NAME}`;
export const GET_DICTIONARIES_BY_CRITERIA = `/api/booking/getdictionariesbycriteria`;
export const GET_AVAILABILITY = '/api/booking/getavailability';
export const GET_VEHICLE_DETAILS = '/api/booking/getcardetails';
export const GET_360_IMAGES = 'https://api.renault-retail-group.fr/images/carousel';
export const VALIDATE_VERIFICATION_CODE = '/api/booking/validateverificationcode';
export const SEND_VERIFICATION_CODE = '/api/booking/sendverificationcode';
export const CREATE_RESERVATION = '/api/booking/createreservation';
export const CREATE_SIMPLE_RESERVATION = '/api/booking/createsimplereservation';
