import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    maxWidth: '1280px',
    margin: '0 auto',
    padding: theme.spacing(0, 2),
    justifyContent: 'center',
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
      padding: 0
    }
  }
}));
